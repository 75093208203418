import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteWebsiteBlock from '@fragment/shared/CompleteWebsiteBlock';
import CompleteVideoEmbedBlock from '@fragment/shared/CompleteVideoEmbedBlock';
import CompleteGoogleDriveBlock from '@fragment/shared/CompleteGoogleDriveBlock';
import CompleteDocumentBlock from '@fragment/shared/CompleteDocumentBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteRelatedListBlock on RelatedListBlockInterface {
        blockType
        relatedDisplayStyle
        related {
            ...CompletePageChooserBlock
            ...CompleteWebsiteBlock
            ...CompleteVideoEmbedBlock
            ...CompleteGoogleDriveBlock
            ...CompleteDocumentBlock
        }
    }
    ${CompletePageChooserBlock}
    ${CompleteWebsiteBlock}
    ${CompleteVideoEmbedBlock}
    ${CompleteGoogleDriveBlock}
    ${CompleteDocumentBlock}
`;
