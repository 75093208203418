import gql from 'graphql-tag';
import CompleteUser from '@fragment/shared/CompleteUser';
// import ConfirmToU from '@forms/ConfirmToU';
// import ConfirmedOrganization from '@forms/ConfirmOrganization';

const PUBLIC_ACCESS = 'PUBLIC';
const PREMIUM_ACCESS = 'PREMIUM';
const USER_DETAIL_FIELDS = [
    {
        name: 'firstName',
        customFormName: 'first-name',
        required: true,
    },
    {
        name: 'lastName',
        customFormName: 'last-name',
        required: true,
    },
    {
        name: 'email',
        customFormName: 'email-address',
        required: true,
    },
    {
        name: 'username',
        customFormName: 'email-address',
    },
];
const PASSWORD_REGISTRATION_FIELDS = [
    {
        name: 'password1',
        customFormName: 'password',
        required: true,
    },
    {
        name: 'password2',
        customFormName: 'confirm-password',
        required: true,
    },
];
const PASSWORD_CHANGE_FIELDS = [
    {
        name: 'oldPassword',
        customFormName: 'old-password',
        label: 'Old Password',
        required: true,
    },
    {
        name: 'newPassword1',
        customFormName: 'password',
        label: 'New Password',
        required: true,
    },
    {
        name: 'newPassword2',
        customFormName: 'confirm-password',
        label: 'Confirm New Password',
        required: true,
    },
];
const USER_ORGANIZATION_FIELDS = [
    {
        name: 'userOrgToggle',
        customFormName: 'user-org-toggle',
    },
    {
        name: 'organization',
        customFormName: 'schooldistrictorganization',
        required: true,
    },
    {
        name: 'organizationName',
        customFormName: 'schooldistrictorganization-name',
    },
    {
        name: 'organizationType',
        customFormName: 'schooldistrictorganization-type',
    },
    {
        name: 'organizationGovernance',
        customFormName: 'schooldistrictorganization-governance-structure',
    },
    {
        name: 'organizationStreet',
        customFormName: 'schooldistrictorganization-street',
    },
    {
        name: 'organizationCity',
        customFormName: 'schooldistrictorganization-city',
    },
    {
        name: 'organizationState',
        customFormName: 'schooldistrictorganization-state',
    },
    {
        name: 'role',
        customFormName: 'role',
        required: true,
    },
];
const USAGE_AGREEMENT_FIELDS = [
    {
        name: 'termsOfUse',
        customFormName: 'terms-of-use',
        required: true,
    },
    {
        name: 'materialUsageAgreement',
        customFormName: 'material-usage-agreement',
        required: true,
    },
    {
        name: 'materialUsage',
        customFormName: 'how-do-you-plan-to-use-this-material',
        required: true,
    },
];
const USER_ADDRESS_FIELDS = [
    {
        name: 'userStreetAddress',
        customFormName: 'street-address',
    },
    {
        name: 'userCity',
        customFormName: 'city',
    },
    {
        name: 'userState',
        customFormName: 'state',
    },
    {
        name: 'userPostalCode',
        customFormName: 'postal-code',
    },
];
export const FIELD_MAP = {
    registration: [
        ...USER_DETAIL_FIELDS,
        ...PASSWORD_REGISTRATION_FIELDS,
        ...USER_ORGANIZATION_FIELDS,
        ...USER_ADDRESS_FIELDS,
        ...USAGE_AGREEMENT_FIELDS,
    ],
    updateAccount: [...USER_DETAIL_FIELDS, ...USER_ORGANIZATION_FIELDS, ...USER_ADDRESS_FIELDS],
    passwordChange: [...PASSWORD_CHANGE_FIELDS],
    login: {
        email: 'email',
        password: 'password',
    },
    customFormToGraphQLNames(mapKey, fields) {
        const fieldMap = FIELD_MAP[mapKey];
        const mappedFields = {};
        fieldMap.forEach((field) => {
            if (fields[field.customFormName] && fields[field.customFormName].length) {
                mappedFields[field.name] = fields[field.customFormName];
            }
        });
        return mappedFields;
    },
    GraphQLToCustomFormNames(mapKey, fields) {
        const fieldMap = FIELD_MAP[mapKey];
        const mappedFields = {};
        fieldMap.forEach((field) => {
            if (!!fields[field.name] && fields[field.name].length) {
                mappedFields[field.customFormName] = mappedFields[field.customFormName]
                    ? mappedFields[field.customFormName].concat(fields[field.name])
                    : fields[field.name];
            }
        });

        if (fields.nonFieldErrors) {
            mappedFields.nonFieldErrors = fields.nonFieldErrors;
        }
        return mappedFields;
    },
};

const CHANGING_EMAIL = 'changingEmail';
const USER_FRAGMENT = CompleteUser;
export const USER_CONTENT_TYPES = {
    RESOURCE: 'resource',
    PROTOCOL: 'protocol',
    MODULE: 'module',
    LESSON: 'lesson',
    UNIT: 'unit',
};

export const USER_CONTENT_SLUG_TYPES = {
    resources: USER_CONTENT_TYPES.RESOURCE,
    protocols: USER_CONTENT_TYPES.PROTOCOL,
    modules: USER_CONTENT_TYPES.MODULE,
    lessons: USER_CONTENT_TYPES.LESSON,
    units: USER_CONTENT_TYPES.UNIT,
};

export const USER_CONTENT_TYPENAMES = {
    [USER_CONTENT_TYPES.RESOURCE]: 'Resources',
    [USER_CONTENT_TYPES.PROTOCOL]: 'Protocols',
    [USER_CONTENT_TYPES.MODULE]: 'Modules',
    [USER_CONTENT_TYPES.LESSON]: 'Lessons',
    [USER_CONTENT_TYPES.UNIT]: 'Units',
};

export const DEFAULT_USER_STATE = () => ({
    isGuest: true,
    folders: [],
    folderConfigs: [],
    access: PUBLIC_ACCESS,
});

export const state = () => ({
    token: '',
    refreshToken: '',
    resendingActivationEmail: false,
    user: DEFAULT_USER_STATE(),
    verified: false,
    changingEmail: false,
    emailChangeVerified: false,
});

export const getters = {
    currentUser: (state, getters) => {
        return {
            ...state.user,
            resourceFolders: getters.resourceFolders,
            protocolFolders: getters.protocolFolders,
        };
    },
    authToken: (state) => state.token,
    verified: (state) => state.verified,
    emailChangeVerified: (state) => state.emailChangeVerified,
    hasPremiumAccess: (state) => state.user.access === PREMIUM_ACCESS,
    subscribeLink: (state) => state.user.subscribeLink,
    changingEmail: (state) => /true/.test(localStorage.getItem(CHANGING_EMAIL)) || state.changingEmail,
    resourceFolders: (_, getters) => getters.foldersByContentType(USER_CONTENT_TYPES.RESOURCE),
    protocolFolders: (_, getters) => getters.foldersByContentType(USER_CONTENT_TYPES.PROTOCOL),
    foldersByContentType: (state) => (contentType) =>
        state.user.folders
            .filter((folder) => folder.allowedTypes.includes(contentType))
            .map((folder) => {
                let items;
                switch (contentType) {
                    case USER_CONTENT_TYPES.RESOURCE:
                        items = folder.resources.objects;
                        break;
                    case USER_CONTENT_TYPES.PROTOCOL:
                        items = folder.protocols.objects;
                        break;
                    default:
                        items = [];
                }
                return {
                    ...folder,
                    items,
                };
            }),
    folderItemsByContentType: () => (folder, contentType) => {
        switch (contentType) {
            case USER_CONTENT_TYPES.RESOURCE:
                return folder.resources;
            case USER_CONTENT_TYPES.PROTOCOL:
                return folder.protocols;
            default:
                return [];
        }
    },
    folderConfigForType: (state) => {
        return (contentType) => {
            return state.user.folderConfigs.find((config) =>
                config.allowedTypes.find((allowedType) => allowedType.type === contentType),
            );
        };
    },
    USER_CONTENT_TYPENAMES: () => USER_CONTENT_TYPENAMES,
    USER_CONTENT_TYPES: () => USER_CONTENT_TYPES,
    USER_CONTENT_SLUG_TYPES: () => USER_CONTENT_SLUG_TYPES,
};

export const mutations = {
    saveTokens(state, { refreshToken, token }) {
        state.token = token;
        state.refreshToken = refreshToken;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
    },

    clearTokens(state) {
        state.token = null;
        state.refreshToken = null;
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
    },

    saveUser(state, { user }) {
        if (!user) {
            return;
        }

        state.user = user;
        state.user.access = state.user.access ? state.user.access : PUBLIC_ACCESS;
        state.user.folders = user.folders || [];

        // Commenting this out until we are ready to turn it on
        // if (!state.user.confirmedOrganization) {
        //     this.$modals.launch({ component: ConfirmedOrganization, closable: false });
        // }

        // Commenting this out until we are ready to turn it on
        // if (!state.user.acceptedLatestTou) {
        //     this.$modals.launch({ component: ConfirmToU, closable: false });
        // }
    },

    resendingActivationEmail(state, status) {
        state.resendingActivationEmail = status;
        if (status) {
            setTimeout(() => this.commit('user/resendingActivationEmail', false), 4000);
        }
    },

    setVerified(state, { success, errors = {} }) {
        const alreadyVerified = errors?.nonFieldErrors?.[0].code === 'already_verified';
        state.verified = success || alreadyVerified;
    },

    setChangingEmail(state, changingEmail) {
        state.changingEmail = changingEmail;
        localStorage.setItem(CHANGING_EMAIL, changingEmail);
    },

    setEmailChangeVerified(state, { success }) {
        state.emailChangeVerified = success;
    },
};

export const actions = {
    async register({ commit, dispatch }, userData) {
        const { mutate } = this.$apolloClient;
        const variables = FIELD_MAP.customFormToGraphQLNames('registration', userData);
        const mutation = await mutate({
            mutation: gql`
                mutation Register(
                    $email: String!
                    $password1: String!
                    $password2: String!
                    $firstName: String!
                    $lastName: String!
                    $organization: String
                    $role: String!
                    $userStreetAddress: String
                    $userCity: String
                    $userState: String
                    $userPostalCode: String
                    $userOrgToggle: String
                ) {
                    register(
                        email: $email
                        password1: $password1
                        password2: $password2
                        firstName: $firstName
                        lastName: $lastName
                        organization: $organization
                        roles: [$role]
                        userStreetAddress: $userStreetAddress
                        userCity: $userCity
                        userState: $userState
                        userPostalCode: $userPostalCode
                        userOrgToggle: $userOrgToggle
                    ) {
                        success
                        errors
                        token
                        refreshToken
                    }
                }
            `,
            variables,
        });
        const { success, errors, token, refreshToken } = mutation.data.register;

        if (success) {
            commit('saveTokens', mutation.data.register);
            dispatch('getCurrentUser');
            dispatch('showEmailVerificationNotice', { email: variables.email });
        }

        return {
            success,
            errors: errors ? FIELD_MAP.GraphQLToCustomFormNames('registration', errors) : errors,
            token,
            refreshToken,
        };
    },

    async updateAccount({ dispatch, state }, userData) {
        const { mutate } = this.$apolloClient;
        const variables = FIELD_MAP.customFormToGraphQLNames('updateAccount', userData);
        const emailChanged = variables.email !== state.user.email;

        if (emailChanged) {
            dispatch('showEmailChangeNotice', { email: variables.email });
        }

        const mutation = await mutate({
            mutation: gql`
                mutation UpdateAccount(
                    $firstName: String!
                    $lastName: String!
                    $organization: String
                    $role: String!
                    $userStreetAddress: String
                    $userCity: String
                    $userState: String
                    $userPostalCode: String
                    $userOrgToggle: String
                ) {
                    updateAccount(
                        firstName: $firstName
                        lastName: $lastName
                        organization: $organization
                        roles: [$role]
                        userStreetAddress: $userStreetAddress
                        userCity: $userCity
                        userState: $userState
                        userPostalCode: $userPostalCode
                        userOrgToggle: $userOrgToggle
                    ) {
                        success
                        errors
                    }
                }
            `,
            variables,
        });

        const { success, errors } = mutation.data.updateAccount;

        if (success) {
            dispatch('getCurrentUser');
        } else if (errors?.nonFieldErrors && errors.nonFieldErrors.find((error) => error.code === 'not_verified')) {
            dispatch('resendActivationEmail', variables.email);
            dispatch('showEmailVerificationNotice', { email: variables.email });
        }

        return {
            success,
            errors: errors ? FIELD_MAP.GraphQLToCustomFormNames('updateAccount', errors) : errors,
        };
    },

    async updateEmail({ commit }, { password, email }) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation SendSecondaryEmailActivation($email: String!, $password: String!) {
                    sendSecondaryEmailActivation(email: $email, password: $password) {
                        success
                        errors
                    }
                }
            `,
            variables: {
                email,
                password,
            },
        });

        const { success, errors } = mutation.data.sendSecondaryEmailActivation;

        if (success) {
            commit('setChangingEmail', true);
        }

        return {
            success,
            errors,
        };
    },

    async swapEmails({ dispatch }, { password }) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation SwapEmails($password: String!) {
                    swapEmails(password: $password) {
                        success
                        errors
                    }
                }
            `,
            variables: {
                password,
            },
        });

        const { success, errors } = mutation.data.swapEmails;

        if (success) {
            await dispatch('refreshToken');
            dispatch('removeSecondaryEmail', { password });
        }

        return { success, errors };
    },

    async removeSecondaryEmail(_, { password }) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation RemoveSecondaryEmail($password: String!) {
                    removeSecondaryEmail(password: $password) {
                        success
                        errors
                    }
                }
            `,
            variables: {
                password,
            },
        });

        return mutation.data.removeSecondaryEmail;
    },

    async passwordChange({ dispatch, commit }, userData) {
        const { mutate } = this.$apolloClient;
        const variables = FIELD_MAP.customFormToGraphQLNames('passwordChange', userData);
        const mutation = await mutate({
            mutation: gql`
                mutation PasswordChange($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
                    passwordChange(
                        oldPassword: $oldPassword
                        newPassword1: $newPassword1
                        newPassword2: $newPassword2
                    ) {
                        success
                        errors
                        token
                        refreshToken
                    }
                }
            `,
            variables,
        });

        const { success, errors } = mutation.data.passwordChange;

        if (success) {
            commit('saveTokens', mutation.data.passwordChange);
            dispatch('getCurrentUser');
        }

        return {
            success,
            errors: errors ? FIELD_MAP.GraphQLToCustomFormNames('passwordChange', errors) : errors,
        };
    },

    async verifyAccount({ commit, dispatch, getters }, token) {
        if (getters.changingEmail) {
            return await dispatch('verifyEmailChange', token);
        }

        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation VerifyAccount($token: String!) {
                    verifyAccount(token: $token) {
                        success
                        errors
                    }
                }
            `,
            variables: {
                token,
            },
        });

        const { success, errors } = mutation.data.verifyAccount;

        commit('setVerified', mutation.data.verifyAccount);

        return {
            success,
            errors,
        };
    },

    async verifyEmailChange({ commit }, token) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation VerifyEmailChange($token: String!) {
                    verifyEmailChange(token: $token) {
                        success
                        errors
                    }
                }
            `,
            variables: {
                token,
            },
        });

        commit('clearTokens');
        commit('setChangingEmail', false);
        commit('setEmailChangeVerified', mutation.data.verifyEmailChange);

        return mutation.data.verifyEmailChange;
    },

    async login({ commit, dispatch }, credentials) {
        const { mutate } = this.$apolloClient;
        commit('clearTokens');
        const mutation = await mutate({
            mutation: gql`
                mutation tokenAuth($email: String!, $password: String!) {
                    tokenAuth(email: $email, password: $password) {
                        success
                        errors
                        token
                        refreshToken
                        unarchiving
                        user {
                            id
                            username
                        }
                    }
                }
            `,
            variables: credentials,
        });

        const { success, errors } = mutation.data.tokenAuth;

        if (success) {
            commit('saveTokens', mutation.data.tokenAuth);
            await dispatch('getCurrentUser');
        } else if (errors?.nonFieldErrors && errors.nonFieldErrors.find((error) => error.code === 'not_verified')) {
            dispatch('resendActivationEmail', credentials.email);
            dispatch('showEmailVerificationNotice', { email: credentials.email });
        }

        return mutation.data.tokenAuth;
    },

    async logout({ commit }) {
        const { mutate } = this.$apolloClient;
        await mutate({
            mutation: gql`
                mutation revokeToken($refreshToken: String!) {
                    revokeToken(refreshToken: $refreshToken) {
                        success
                        errors
                    }
                }
            `,
            variables: {
                refreshToken: localStorage.getItem('refreshToken') || '',
            },
        });

        commit('clearTokens');
        commit('saveUser', { user: DEFAULT_USER_STATE() });
        this.$router.push('/');
    },

    async getCurrentUser({ commit, dispatch }) {
        const { query } = this.$apolloClient;

        await dispatch('refreshToken');
        const result = await query({
            fetchPolicy: 'network-only',
            query: gql`
                query me {
                    me {
                        ...CompleteUser
                    }
                }
                ${USER_FRAGMENT}
            `,
        });

        commit('saveUser', { user: result.data.me });
    },

    async refreshToken({ commit }) {
        const { mutate } = this.$apolloClient;
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken && refreshToken !== '') {
            const mutation = await mutate({
                mutation: gql`
                    mutation refreshToken($refreshToken: String!) {
                        refreshToken(refreshToken: $refreshToken) {
                            success
                            errors
                            token
                            refreshToken
                        }
                    }
                `,
                variables: {
                    refreshToken,
                },
            });

            commit('saveTokens', mutation.data.refreshToken);
        }
    },

    async resendActivationEmail({ commit }, email) {
        const { mutate } = this.$apolloClient;

        commit('resendingActivationEmail', true);
        const mutation = await mutate({
            mutation: gql`
                mutation resendActivationEmail($email: String!) {
                    resendActivationEmail(email: $email) {
                        success
                        errors
                    }
                }
            `,
            variables: {
                email,
            },
        });

        return mutation.data.resendActivationEmail;
    },

    async sendPasswordResetEmail(_, email) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation sendPasswordResetEmail($email: String!) {
                    sendPasswordResetEmail(email: $email) {
                        success
                        errors
                    }
                }
            `,
            variables: {
                email,
            },
        });

        return mutation.data.sendPasswordResetEmail;
    },

    async resetPassword(_, { token, newPassword1, newPassword2 }) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation passwordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
                    passwordReset(token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2) {
                        success
                        errors
                    }
                }
            `,
            variables: {
                token,
                newPassword1,
                newPassword2,
            },
        });

        return mutation.data.passwordReset;
    },

    async createUserFolder(_, { name, type }) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation createUserFolder($name: String!, $type: String!) {
                    createUserFolder(name: $name, type: $type) {
                        ok
                        userFolderId
                        errors {
                            key
                            message
                        }
                    }
                }
            `,
            variables: {
                name,
                type,
            },
        });

        return {
            ...mutation.data.createUserFolder,
            id: mutation.data.createUserFolder.userFolderId,
        };
    },

    async saveItemToUserFolder(_, { itemId, itemType, userFolderId }) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation saveItemToUserFolder($itemId: Int!, $itemType: String!, $userFolderId: Int!) {
                    saveItemToUserFolder(itemId: $itemId, itemType: $itemType, userFolderId: $userFolderId) {
                        ok
                        errors {
                            key
                            message
                        }
                    }
                }
            `,
            variables: {
                itemId: Number(itemId),
                itemType,
                userFolderId: Number(userFolderId),
            },
        });

        return mutation.data.saveItemToUserFolder;
    },

    async removeItemFromUserFolder(_, { itemId, itemType, userFolderId }) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation removeItemFromUserFolder($itemId: Int!, $itemType: String!, $userFolderId: Int!) {
                    removeItemFromUserFolder(itemId: $itemId, itemType: $itemType, userFolderId: $userFolderId) {
                        ok
                        errors {
                            key
                            message
                        }
                    }
                }
            `,
            variables: {
                itemId: Number(itemId),
                itemType,
                userFolderId: Number(userFolderId),
            },
        });

        return mutation.data.removeItemFromUserFolder;
    },

    async createResourceFolder({ dispatch }, { name }) {
        const mutationData = await dispatch('createUserFolder', {
            name,
            type: USER_CONTENT_TYPES.RESOURCE,
        });

        return mutationData;
    },

    async renameUserFolder(_, { id, name }) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation renameUserFolder($id: Int!, $name: String!) {
                    renameUserFolder(id: $id, name: $name) {
                        ok
                        errors {
                            key
                            message
                        }
                    }
                }
            `,
            variables: {
                id,
                name,
            },
        });

        return mutation.data.renameUserFolder;
    },

    async deleteUserFolder(_, { id }) {
        const { mutate } = this.$apolloClient;
        const mutation = await mutate({
            mutation: gql`
                mutation deleteUserFolder($id: Int!) {
                    deleteUserFolder(id: $id) {
                        ok
                        errors {
                            key
                            message
                        }
                    }
                }
            `,
            variables: {
                id,
            },
        });

        return mutation.data.deleteUserFolder;
    },

    async addResourceToFolder({ dispatch }, { resourceId, resourceFolderId }) {
        const mutationData = await dispatch('saveItemToUserFolder', {
            itemId: resourceId,
            userFolderId: resourceFolderId,
            itemType: USER_CONTENT_TYPES.RESOURCE,
        });

        return mutationData;
    },

    async removeResourceFromFolder({ dispatch }, { resourceId, resourceFolderId }) {
        const mutationData = await dispatch('removeItemFromUserFolder', {
            itemId: resourceId,
            itemType: USER_CONTENT_TYPES.RESOURCE,
            userFolderId: resourceFolderId,
        });
        return mutationData;
    },

    async upgradeAccount({ state }, { fromPage } = {}) {
        if (fromPage) {
            localStorage.setItem('fromPage', fromPage);
        }
        window.open(`${state.user.subscribeLink}?prefilled_email=${state.user.email}`, '_blank');
    },

    async cancelSubscription({ dispatch }) {
        this.$notices.confirm({
            message: 'Are you sure you want to cancel your subscription?',
            actions: [
                {
                    label: 'Yes',
                    callback: () => dispatch('startCancellationFlow'),
                },
                { label: 'No' },
            ],
        });
    },

    async startCancellationFlow() {
        const waitNotice = this.$notices.show({
            title: 'Please wait...',
            message: 'We are sending you to our payment provider to cancel your subscription',
            actions: false,
        });

        let cancelSubscription = {};
        const { mutate } = this.$apolloClient;
        try {
            const mutation = await mutate({
                mutation: gql`
                    mutation cancelSubscription {
                        cancelSubscription {
                            ok
                            errors {
                                key
                                message
                            }
                            cancelSessionUrl
                        }
                    }
                `,
            });
            cancelSubscription = mutation.data.cancelSubscription;
        } catch (e) {
            cancelSubscription = { ok: false };
        }

        if (cancelSubscription.ok) {
            setTimeout(() => (window.location = cancelSubscription.cancelSessionUrl), 3000);
        } else {
            waitNotice.close();
            this.$notices.show({
                title: 'Something went wrong',
                message: 'Please try again later...',
            });
        }
    },

    async showEmailVerificationNotice(_, { email }) {
        this.$notices.show({
            component: 'EmailVerificationNotice',
            props: {
                email,
            },
        });
    },

    async showEmailChangeNotice(_, { email }) {
        this.$notices.show({
            component: 'EmailChangeNotice',
            props: {
                email,
            },
        });
    },
};
