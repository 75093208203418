import Vue from 'vue';

Vue.directive('closable', {
    bind(el, binding, vnode) {
        el.handleOutsideClick = (e) => {
            e.stopPropagation();
            const { handler, exclude } = binding.value;
            let clickedOnExcludedEl = false;
            exclude.forEach((refName) => {
                if (!clickedOnExcludedEl) {
                    const excludedEl = vnode.context.$refs[refName];
                    if (excludedEl && excludedEl.contains(e.target)) {
                        clickedOnExcludedEl = true;
                    }
                }
            });
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                vnode.context[handler]();
            }
        };
        document.addEventListener('click', el.handleOutsideClick);
        document.addEventListener('touchstart', el.handleOutsideClick);
    },

    unbind(el) {
        document.removeEventListener('click', el.handleOutsideClick);
        document.removeEventListener('touchstart', el.handleOutsideClick);
    },
});
