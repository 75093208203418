import { v4 as uuidv4 } from 'uuid';
import SiteNotice from '@global/SiteNotice';
import * as notices from '@global/notices';

const modals = {};

export const state = () => ({
    modals: [],
});

export const getters = {
    modals(state) {
        return state.modals.map((modalID) => modals[modalID]);
    },

    hasModals(state) {
        return state.modals.length > 0;
    },

    notice(state) {
        const modalID = state.modals.find((modalID) => modals[modalID].notice);
        return modals[modalID];
    },
};

export const mutations = {
    addModal(state, modal) {
        const { uuid } = modal;
        modals[uuid] = modal;
        state.modals.push(uuid);
        return modal;
    },

    removeModal(state, { uuid }) {
        const modal = modals[uuid];
        if (modal.callback) {
            modal.callback({ modalClosed: true });
        }
        if (modal.source) {
            modal.source.focus();
        }
        state.modals = state.modals.filter((modalID) => modalID !== uuid);
        delete modals[uuid];
    },

    removeAllModals(state) {
        state.modals
            .filter((modalID) => {
                return modals[modalID].closable;
            })
            .forEach((modalID) => this.commit('siteModals/removeModal', modals[modalID]));
    },
};

export const actions = {
    addModal({ commit }, { component, props, notice = false, callback = false, source, closable = true }) {
        const modal = {
            uuid: uuidv4(),
            component,
            props,
            notice,
            callback,
            source,
            closable,
        };
        commit('addModal', modal);
        return modal;
    },

    removeModal({ commit }, modal) {
        commit('removeModal', modal);
    },

    removeAllModals({ commit }) {
        commit('removeAllModals');
    },

    showNotice({ dispatch, getters }, config) {
        const component = (config.component && notices[config.component]) || SiteNotice;
        const props = config.props || config;

        if (getters.notice) {
            dispatch('removeModal', getters.notice);
        }

        const modal = dispatch('addModal', {
            component,
            props,
            callback: config.callback,
            notice: true,
        });

        return modal;
    },
};
